import { __assign, __awaiter, __generator } from "tslib";
import React, { useEffect, useState } from "react";
import { Toolbar, Button, Typography } from "@mui/material";
import axios from "axios";
import Navigation from "../navigation/Navigation";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import Loader from "../../components/Loader";
import { getStaff } from "../../helpers/helpers";
import { Scheduler, useScheduler } from "@aldabil/react-scheduler";
import { useAdmin } from "../../context/admin_context";
import { useSnackbar } from "notistack";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import { useAuth } from "../../context/auth_context";
import { Box } from "@mui/system";
var convertUserType = function (data, type) {
    if (type === "users") {
        var formattedUsers_1 = [];
        data.map(function (usr) {
            formattedUsers_1.push({ id: usr.uid, text: usr.name, value: usr.uid });
        });
        return formattedUsers_1;
    }
    if (type === "locations") {
        var locations_1 = [];
        data.map(function (loc) {
            locations_1.push({
                id: loc.locationId,
                text: loc.locationName,
                value: loc.locationId
            });
        });
        return locations_1;
    }
};
var SchedulerHome = function () {
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useState(null), formattedUsers = _b[0], setFormattedUsers = _b[1];
    var _c = useState(null), users = _c[0], setUsers = _c[1];
    var _d = useState(null), formattedLocations = _d[0], setFormattedLocations = _d[1];
    var addShift = function (shift) { return __awaiter(void 0, void 0, void 0, function () {
        var authToken, resp, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    setIsLoading(true);
                    authToken = window.localStorage.getItem("auth_token");
                    console.log("authToken:", authToken);
                    console.log("shift:", shift);
                    return [4 /*yield*/, axios.post("".concat(process.env.REACT_APP_API_ADDRESS_2, "/shifts"), 
                        //prettier-ignore
                        shift, {
                            headers: { api_key: authToken }
                        })];
                case 1:
                    resp = _a.sent();
                    console.log("resp from addShift:", resp.data);
                    setIsLoading(false);
                    return [2 /*return*/, resp.data];
                case 2:
                    error_1 = _a.sent();
                    console.log(error_1.message);
                    console.log(error_1);
                    setIsLoading(false);
                    enqueueSnackbar("Something went wrong when trying to add shift", {
                        variant: "error"
                    });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var getShifts = function () { return __awaiter(void 0, void 0, void 0, function () {
        var authToken, resp, formattedData, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    authToken = window.localStorage.getItem("auth_token");
                    console.log("authToken:", authToken);
                    return [4 /*yield*/, axios.get("".concat(process.env.REACT_APP_API_ADDRESS_2, "/shifts"), 
                        //prettier-ignore
                        {
                            headers: { api_key: authToken }
                        })];
                case 1:
                    resp = _a.sent();
                    console.log("resp from getShifts:", resp.data);
                    if (resp.data) {
                        formattedData = resp.data.map(function (shift) {
                            var formattedShift = __assign({}, shift);
                            formattedShift.start = new Date(formattedShift.start);
                            formattedShift.end = new Date(formattedShift.end);
                            return formattedShift;
                        });
                        console.log("formattedData:", formattedData);
                        return [2 /*return*/, formattedData];
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    console.log(error_2.message);
                    console.log(error_2);
                    enqueueSnackbar("Something went wrong when trying to get shifts", {
                        variant: "error"
                    });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var currentUser = useAuth().currentUser;
    var _e = useAdmin(), locations = _e.locations, selectedStore = _e.selectedStore, setSelectedStore = _e.setSelectedStore;
    var _f = useScheduler(), resourceViewMode = _f.resourceViewMode, setResourceViewMode = _f.setResourceViewMode, setEvents = _f.setEvents, events = _f.events, resources = _f.resources, serResources = _f.serResources;
    var enqueueSnackbar = useSnackbar().enqueueSnackbar;
    var getStaffHelper = function () { return __awaiter(void 0, void 0, void 0, function () {
        var resp, formattedUsers_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getStaff(setIsLoading, enqueueSnackbar)];
                case 1:
                    resp = _a.sent();
                    if (resp) {
                        console.log("userssss:", resp);
                        formattedUsers_2 = convertUserType(resp, "users");
                        serResources(resp);
                        setFormattedUsers(formattedUsers_2);
                        setUsers(resp);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        getStaffHelper();
    }, []);
    useEffect(function () {
        if (locations) {
            setFormattedLocations(convertUserType(locations, "locations"));
        }
    }, [locations]);
    if (isLoading) {
        return (React.createElement("div", { style: { height: "100%", width: "100%" } },
            React.createElement(Loader, null)));
    }
    var onConfirm = function (event, action) { return __awaiter(void 0, void 0, void 0, function () {
        var newShift, resp, createdShift;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log("event:", event);
                    console.log("action:", action);
                    newShift = {
                        title: event.title,
                        uid: event.uid,
                        created_at: new Date(),
                        end: new Date(event.end),
                        start: new Date(event.start),
                        updated_at: new Date(event.start),
                        location_id: event.location,
                        paid: true,
                        position_id: "staff",
                        shift_creator_uid: currentUser.uid
                    };
                    return [4 /*yield*/, addShift(newShift)];
                case 1:
                    resp = _a.sent();
                    if (!resp)
                        return [2 /*return*/];
                    createdShift = __assign({}, resp);
                    createdShift.start = new Date(createdShift.start);
                    createdShift.end = new Date(createdShift.end);
                    console.log("createdShift:", createdShift);
                    return [2 /*return*/, createdShift];
            }
        });
    }); };
    var onDelete = function (deletedId) {
        console.log("deleting ", deletedId);
        return new Promise(function (resolve, reject) {
            resolve(deletedId);
        });
    };
    return (React.createElement(Box, { sx: { width: "100%", display: "flex" } },
        isLoading && React.createElement(Loader, null),
        React.createElement(Navigation, null),
        React.createElement(Box, { sx: {
                width: "100%",
                overflow: "scroll",
                display: "flex",
                flexDirection: "column"
            } },
            React.createElement(Box, { sx: { backgroundColor: "rgb(247, 250, 253)" } },
                React.createElement(Toolbar, { sx: {
                        display: "flex",
                        height: "10vh",
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        boxShadow: "0px 0px 15px 5px rgba(0, 0, 0, 0.2)"
                    } },
                    React.createElement(LocationBreadCrumb, null))),
            React.createElement(Box, { sx: { width: "100%", padding: "2rem 1rem" } },
                React.createElement("div", { style: { textAlign: "center" } },
                    React.createElement("span", null, "Resource View Mode: "),
                    React.createElement(Button, { color: resourceViewMode === "default" ? "primary" : "inherit", variant: resourceViewMode === "default" ? "contained" : "text", size: "small", onClick: function () { return setResourceViewMode("default"); } }, "Default"),
                    React.createElement(Button, { color: resourceViewMode === "tabs" ? "primary" : "inherit", variant: resourceViewMode === "tabs" ? "contained" : "text", size: "small", onClick: function () { return setResourceViewMode("tabs"); } }, "Tabs")),
                formattedLocations && formattedUsers && users && (React.createElement(Scheduler, { direction: "ltr", getRemoteEvents: getShifts, resources: resources, day: {
                        startHour: 0,
                        endHour: 23,
                        step: 720,
                        navigation: true
                    }, week: {
                        weekDays: [0, 1, 2, 3, 4, 5, 6],
                        weekStartOn: 0,
                        startHour: 0,
                        endHour: 23,
                        step: 720,
                        navigation: true,
                        disableGoToDay: false
                    }, resourceFields: {
                        idField: "uid",
                        textField: "name",
                        subTextField: "email"
                    }, 
                    // customEditor={(scheduler: SchedulerHelpers) => {
                    //   console.log("scheduler:", scheduler);
                    //   return <Box></Box>;
                    // }}
                    onConfirm: onConfirm, onDelete: onDelete, fields: [
                        {
                            name: "uid",
                            type: "select",
                            // Should provide options with type:"select"
                            options: formattedUsers,
                            config: {
                                label: "User",
                                required: true,
                                errMsg: "Plz Select User"
                            }
                        },
                        {
                            name: "location",
                            type: "select",
                            // Should provide options with type:"select"
                            options: formattedLocations,
                            config: {
                                label: "Sites",
                                required: true,
                                errMsg: "Plz Select a site"
                            }
                        },
                        {
                            name: "Description",
                            type: "input",
                            "default": "Default Value...",
                            config: { label: "Details", multiline: true, rows: 4 }
                        },
                    ], viewerExtraComponent: function (fields, event) {
                        return (React.createElement("div", null, fields.map(function (field, i) {
                            if (field.name === "admin_id") {
                                var admin = field.options.find(function (fe) { return fe.id === event.admin_id; });
                                return (React.createElement(Typography, { key: i, style: {
                                        display: "flex",
                                        alignItems: "center"
                                    }, color: "textSecondary", variant: "caption", noWrap: true },
                                    React.createElement(PersonRoundedIcon, null),
                                    " ",
                                    admin.text));
                            }
                            else {
                                return "";
                            }
                        })));
                    } }))))));
};
export default SchedulerHome;
