import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useAdmin } from "../../context/admin_context";
import { getSalesData } from "../../helpers/helpers";
import { colors } from "../../constants/constants";
import PieChartComp from "./PieChartComp";
import BarChartComp from "./BarChartComp";
import {
  Card,
  CardContent,
  CardActions,
  ListItem,
  ListItemAvatar,
  Button,
  ListItemText,
  Divider,
  Typography,
} from "@mui/material";
import LineChartComp from "./LineChartComp";
import ImageIcon from "@mui/icons-material/Image";
import { Bar, Line, Pie, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  ArcElement,
} from "chart.js";
import Loader from "../../components/Loader";
import {
  VictoryBar,
  VictoryChart,
  VictoryTheme,
  VictoryZoomContainer,
  VictoryAxis,
  VictoryPolarAxis,
  VictoryLabel,
  Arc,
  VictoryGroup,
  VictoryLegend,
  VictoryLine,
} from "victory";
import VATBreakdownTable from "../../components/VATBreakdownTable";

const SalesComp = ({ timePeriod }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [salesData, setSalesData] = useState(null);
  const [clerkData, setClerkData] = useState(null);
  const [pieChartNumber, setPieChartNumber] = useState(null);
  const [pieChartAmount, setPieChartAmount] = useState(null);
  const [pieChartProducts, setPieChartProducts] = useState(null);
  const [pieChartAttributes, setPieChartAttributes] = useState(null);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    LineElement,
    PointElement,
    ArcElement
  );

  const [all, setAll] = useState(null);
  const [card, setCard] = useState(null);
  const [cash, setCash] = useState(null);
  const [split, setSplit] = useState(null);

  const { selectedStore } = useAdmin();

  const fetchSalesData = async () => {
    if (!selectedStore) {
      alert("Please Select a store to fetch sales for");
      return;
    }

    if (!timePeriod?.startTimestamp || !timePeriod?.endTimestamp) {
      alert("Please Select a start and end time for sales");
      return;
    }
    setIsLoading(true);

    await getSalesData(
      selectedStore,
      setSalesData,
      setPieChartAmount,
      setPieChartNumber,
      setAll,
      setCard,
      setCash,
      setSplit,
      setPieChartAttributes,
      setClerkData,
      setPieChartProducts,
      timePeriod
    );
    setIsLoading(false);
  };

  useEffect(() => {
    fetchSalesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("salesData:", JSON.stringify(salesData));

  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Loader />
      </div>
    );
  }

  if (!salesData) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <h2>Something went wrong. Please try again </h2>
      </div>
    );
  }

  const options = {
    aspectRatio: 1,
    animation: false,
  };

  const ClerkData = () => {
    const [showCount, setShowCount] = useState(true);

    const clerkDataCount = {
      labels: clerkData.map((d) => d.name),
      datasets: [
        {
          label: "Sales Count",

          data: clerkData.map((d) => d.count.toFixed(0)),
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };
    const clerkDataAmount = {
      labels: clerkData.map((d) => d.name),
      datasets: [
        {
          label: "Sales Data",

          data: clerkData.map((d) => d.total.toFixed(2)),
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };
    return (
      <Card sx={{ width: "48%", margin: "0 1% 1% 1%;" }}>
        <CardContent style={{ width: "100%", overflow: "hidden" }}>
          {/* <h3 className="header">Clerk Data</h3> */}
          <Typography gutterBottom variant="h5" component="div">
            Clerk Data
          </Typography>
          {showCount ? (
            <Bar options={options} data={clerkDataCount} />
          ) : (
            <Bar options={options} data={clerkDataAmount} />
          )}
        </CardContent>
        <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setShowCount(!showCount);
            }}>
            {showCount ? "Show amount" : "Show Count"}
          </Button>
        </CardActions>
      </Card>
    );
  };

  const OrderData = () => {
    const [showCount, setShowCount] = useState(true);

    const orderDataCount = {
      labels: pieChartAmount.map((d) => d.name),
      datasets: [
        {
          label: "Orders Count",

          data: pieChartAmount.map((d) => +d.count.toFixed(0)),
          backgroundColor: ["rgba(255, 99, 132, 0.5)", colors.color4],
        },
      ],
    };
    const orderDataAmount = {
      labels: pieChartAmount.map((d) => d.name),
      datasets: [
        {
          label: "Orders Sales",

          data: pieChartAmount.map((d) => +d.amount.toFixed(2)),
          backgroundColor: ["rgba(255, 99, 132, 0.5)", colors.color4],
        },
      ],
    };

    return (
      <Card sx={{ width: "48%", margin: "0 1% 1% 1%;" }}>
        <CardContent style={{ width: "100%", overflow: "hidden" }}>
          <Typography
            color="WindowText"
            gutterBottom
            variant="h5"
            component="div">
            Cash - Card
          </Typography>
          {showCount ? (
            <Doughnut options={options} data={orderDataCount} />
          ) : (
            <Doughnut options={options} data={orderDataAmount} />
          )}
        </CardContent>
        <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setShowCount(!showCount);
            }}>
            {showCount ? "Show amount" : "Show Count"}
          </Button>
        </CardActions>
      </Card>
    );
  };

  const ProductData = () => {
    const [showCount, setShowCount] = useState(true);

    const productDataCount = {
      labels: pieChartProducts.map((d) => d.name),
      datasets: [
        {
          label: "Orders Count",

          data: pieChartProducts.map((d) => +d.count.toFixed(0)),
          backgroundColor: ["rgba(255, 99, 132, 0.5)", colors.color4],
        },
      ],
    };

    const productDataAmount = {
      labels: pieChartProducts.map((d) => d.name),
      datasets: [
        {
          label: "Orders Count",

          data: pieChartProducts.map((d) => +d.total.toFixed(0)),
          backgroundColor: ["rgba(255, 99, 132, 0.5)", colors.color4],
        },
      ],
    };

    return (
      <Card sx={{ width: "98%", margin: "0 1% 1% 1%;" }}>
        <CardContent
          style={{
            // border: "1px solid black",

            width: "100%",
            overflow: "hidden",
          }}>
          <Typography gutterBottom variant="h5" component="div">
            Product Data
          </Typography>
          {showCount ? (
            <Bar options={options} data={productDataCount} />
          ) : (
            <Bar options={options} data={productDataAmount} />
          )}
        </CardContent>
        <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setShowCount(!showCount);
            }}>
            {showCount ? "Show amount" : "Show Count"}
          </Button>
        </CardActions>
      </Card>
    );
  };

  const AttributeData = ({ title, data }) => {
    const [showCount, setShowCount] = useState(true);

    const attributeDataCount = {
      labels: data.map((d) => d.name),
      datasets: [
        {
          label: "Orders Count",

          data: data.map((d) => +d.count.toFixed(0)),
          backgroundColor: ["rgba(255, 99, 132, 0.5)", colors.color4],
        },
      ],
    };
    const attributeDataAmount = {
      labels: data.map((d) => d.name),
      datasets: [
        {
          label: "Orders Sales",

          data: data.map((d) => +d.total.toFixed(2)),
          backgroundColor: ["rgba(255, 99, 132, 0.5)", colors.color4],
        },
      ],
    };

    return (
      <Card sx={{ width: "48%", margin: "0 1% 1% 1%;" }}>
        <CardContent style={{ width: "100%", overflow: "hidden" }}>
          <Typography gutterBottom variant="h5" component="div">
            {title === "product_category" ? "Product Category" : title}
          </Typography>
          {showCount ? (
            <Bar options={options} data={attributeDataCount} />
          ) : (
            <Bar options={options} data={attributeDataAmount} />
          )}
        </CardContent>
        <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setShowCount(!showCount);
            }}>
            {showCount ? "Show amount" : "Show Count"}
          </Button>
        </CardActions>
      </Card>
    );
  };

  const AllHourlySales = () => {
    const [showCount, setShowCount] = useState(true);

    const allDataCount = {
      labels: all.map((d) => d.hour),
      datasets: [
        {
          label: "Sales Count",

          data: all.map((d) => d.count.toFixed(0)),
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };

    const allDataAmount = {
      labels: all.map((d) => d.hour),
      datasets: [
        {
          label: "Sales Count",

          data: all.map((d) => d.total.toFixed(2)),
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };

    return (
      <Card sx={{ width: "48%", margin: "0 1% 1% 1%;" }}>
        <CardContent style={{ width: "100%", overflow: "hidden" }}>
          <Typography gutterBottom variant="h5" component="div">
            All Hourly Sales
          </Typography>
          {showCount ? (
            <Line options={options} data={allDataCount} />
          ) : (
            <Line options={options} data={allDataAmount} />
          )}
        </CardContent>
        <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setShowCount(!showCount);
            }}>
            {showCount ? "Show amount" : "Show Count"}
          </Button>
        </CardActions>
      </Card>
    );
  };

  const CardHourlySales = () => {
    const [showCount, setShowCount] = useState(true);

    const cardDataCount = {
      labels: card.map((d) => d.hour),
      datasets: [
        {
          label: "Card hourly sales",

          data: card.map((d) => d.count.toFixed(0)),
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };

    const cardDataAmount = {
      labels: card.map((d) => d.hour),
      datasets: [
        {
          label: "Card hourly sales",

          data: card.map((d) => d.total.toFixed(2)),
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };

    return (
      <Card sx={{ width: "48%", margin: "0 1% 1% 1%;" }}>
        <CardContent style={{ width: "100%", overflow: "hidden" }}>
          <Typography gutterBottom variant="h5" component="div">
            Card Hourly Sales
          </Typography>
          {showCount ? (
            <Line options={options} data={cardDataCount} />
          ) : (
            <Line options={options} data={cardDataAmount} />
          )}
        </CardContent>
        <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setShowCount(!showCount);
            }}>
            {showCount ? "Show amount" : "Show Count"}
          </Button>
        </CardActions>
      </Card>
    );
  };

  const CashHourlySales = () => {
    const [showCount, setShowCount] = useState(true);

    const cashDataCount = {
      labels: card.map((d) => d.hour),
      datasets: [
        {
          label: "Card hourly sales",

          data: card.map((d) => d.count.toFixed(0)),
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };

    const cashDataAmount = {
      labels: card.map((d) => d.hour),
      datasets: [
        {
          label: "Card hourly sales",

          data: card.map((d) => d.total.toFixed(2)),
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };

    return (
      <Card sx={{ width: "48%", margin: "0 1% 1% 1%;" }}>
        <CardContent style={{ width: "100%", overflow: "hidden" }}>
          <Typography gutterBottom variant="h5" component="div">
            Cash Hourly Sales
          </Typography>
          {showCount ? (
            <Line options={options} data={cashDataCount} />
          ) : (
            <Line options={options} data={cashDataAmount} />
          )}
        </CardContent>
        <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setShowCount(!showCount);
            }}>
            {showCount ? "Show amount" : "Show Count"}
          </Button>
        </CardActions>
      </Card>
    );
  };

  return (
    <Wrapper>
      <div className="left-container">
        {clerkData && clerkData?.length > 0 ? (
          <ClerkData />
        ) : (
          <div className="chart-container">
            <Typography gutterBottom variant="h5" component="div">
              No Clerk Data to show
            </Typography>
          </div>
        )}
        {pieChartAmount && pieChartAmount?.length > 0 ? (
          <OrderData />
        ) : (
          <div className="chart-container">
            <Typography gutterBottom variant="h5" component="div">
              No Order Data to show
            </Typography>
          </div>
        )}
        {salesData?.tax_breakdown &&
        Object.values(salesData?.tax_breakdown)?.length > 0 ? (
          <VATBreakdownTable
            taxData={Object.values(salesData?.tax_breakdown)}
          />
        ) : (
          <div className="chart-container">
            <Typography gutterBottom variant="h5" component="div">
              No VAT Data To Show
            </Typography>
          </div>
        )}
        {all && all?.length > 0 ? (
          <AllHourlySales />
        ) : (
          <div className="chart-container">
            <Typography gutterBottom variant="h5" component="div">
              No "All" hourly sales Data To Show
            </Typography>
          </div>
        )}
        {card && card?.length > 0 ? (
          <CardHourlySales />
        ) : (
          <div className="chart-container">
            <Typography gutterBottom variant="h5" component="div">
              No "Card" hourly sales Data To Show
            </Typography>
          </div>
        )}
        {cash && cash?.length > 0 ? (
          <CashHourlySales />
        ) : (
          <div className="chart-container">
            <Typography gutterBottom variant="h5" component="div">
              No "Cash" hourly sales Data To Show
            </Typography>
          </div>
        )}
        {pieChartAttributes &&
        Object.values(pieChartAttributes) &&
        Object.values(pieChartAttributes)?.length > 0 ? (
          Object.values(pieChartAttributes).map((attr, i) => {
            console.log("attr:", attr);
            let data = Object.values(attr);
            let dataTitles = Object.keys(attr);
            let title = Object.keys(pieChartAttributes)[i];
            console.log("pieChartAttributes:", pieChartAttributes);
            return <AttributeData key={i} title={title} data={data} />;
          })
        ) : (
          <div className="chart-container">
            <Typography gutterBottom variant="h5" component="div">
              No Category Data To Show
            </Typography>
          </div>
        )}
        {pieChartProducts && pieChartProducts?.length > 0 ? (
          <ProductData />
        ) : (
          <div className="chart-container">
            <Typography gutterBottom variant="h5" component="div">
              No Product Data To Show
            </Typography>
          </div>
        )}
      </div>
      <Card className="right-container">
        <CardContent style={{ width: "100%", overflow: "hidden" }}>
          <h3 className="header" style={{ marginBottom: "2%" }}>
            <Typography gutterBottom variant="h5">
              Sales Data
            </Typography>
          </h3>
          <ListItem
            secondaryAction={
              <h4 className="data-value">{salesData?.orders_count || 0}</h4>
            }>
            <ListItemText primary="Number of Orders:" />
          </ListItem>
          <Divider />
          <ListItem
            secondaryAction={
              <h4 className="data-value">
                £{salesData?.gross_sales?.toFixed(2) || "0.00"}
              </h4>
            }>
            <ListItemText
              primary="Gross Sales:"
              secondary="Pre discount sales incl VAT"
            />
          </ListItem>
          <Divider />
          <ListItem
            secondaryAction={
              <h4 className="data-value">
                £{salesData?.net_sales?.toFixed(2) || "0.00"}
              </h4>
            }>
            <ListItemText
              primary="Net Sales:"
              secondary="Amount taken incl VAT"
            />
          </ListItem>
          <Divider />
          <ListItem
            secondaryAction={
              <h4 className="data-value">{salesData?.refunded_count}</h4>
            }>
            <ListItemText primary="Refunded Orders:" />
          </ListItem>
          <Divider />
          <ListItem
            secondaryAction={
              <h4 className="data-value">
                £{salesData?.refunded_orders || "0.00"}
              </h4>
            }>
            <ListItemText primary="Refunded Total:" />
          </ListItem>
          <Divider />
          <ListItem
            secondaryAction={
              <h4 className="data-value">{salesData?.coupon_count || "0"}</h4>
            }>
            <ListItemText primary="Coupon Count:" />
          </ListItem>
          <Divider />{" "}
          <ListItem
            secondaryAction={
              <h4 className="data-value">
                £{salesData?.coupon_value.toFixed(2) || "0.00"}
              </h4>
            }>
            <ListItemText primary="Coupon Total:" />
          </ListItem>
          <Divider />
          <ListItem
            secondaryAction={
              <h4 className="data-value">
                {salesData?.cash_count?.toFixed(0) || "0"}
              </h4>
            }>
            <ListItemText primary="Cash Orders:" />
          </ListItem>
          <Divider />
          <ListItem
            secondaryAction={
              <h4 className="data-value">
                £{salesData?.cash_total?.toFixed(2) || "0.00"}
              </h4>
            }>
            <ListItemText primary="Cash Total:" />
          </ListItem>
          <Divider />
          <ListItem
            secondaryAction={
              <h4 className="data-value">
                {salesData?.card_count?.toFixed(0) || "0"}
              </h4>
            }>
            <ListItemText primary="Cash Orders:" />
          </ListItem>
          <Divider />
          <ListItem
            secondaryAction={
              <h4 className="data-value">
                £{salesData?.card_total?.toFixed(2) || "0.00"}
              </h4>
            }>
            <ListItemText primary="Card Total:" />
          </ListItem>
          <Divider />
          <ListItem
            secondaryAction={
              <h4 className="data-value">
                £{salesData?.split_total?.toFixed(2) || "0.00"}
              </h4>
            }>
            <ListItemText primary="Split Total:" />
          </ListItem>
          <Divider />
          <ListItem
            secondaryAction={
              <h4 className="data-value">
                {salesData?.item_discount_count?.toFixed(0) || "0"}
              </h4>
            }>
            <ListItemText primary="Item Discounts Count:" />
          </ListItem>
          <Divider />
          <ListItem
            secondaryAction={
              <h4 className="data-value">
                £{salesData?.item_discount_total?.toFixed(2) || "0.00"}
              </h4>
            }>
            <ListItemText primary="Item Discounts Amount:" />
          </ListItem>
          <Divider />
          <ListItem
            secondaryAction={
              <h4 className="data-value">
                {salesData?.global_discount_count?.toFixed(0) || "0"}
              </h4>
            }>
            <ListItemText primary="Global Discounts Count:" />
          </ListItem>
          <Divider />
          <ListItem
            secondaryAction={
              <h4 className="data-value">
                £{salesData?.global_discount_total?.toFixed(2) || "0.00"}
              </h4>
            }>
            <ListItemText primary="Global Discounts Amount:" />
          </ListItem>
          <Divider />
          <ListItem
            secondaryAction={
              <h4 className="data-value">
                {salesData?.pay_ins_count?.toFixed(0) || "0"}
              </h4>
            }>
            <ListItemText primary="Pay Ins Count:" />
          </ListItem>
          <Divider />
          <ListItem
            secondaryAction={
              <h4 className="data-value">
                £{salesData?.pay_ins_total?.toFixed(2) || "0.00"}
              </h4>
            }>
            <ListItemText primary="Pay Ins Amount:" />
          </ListItem>
          <Divider />
        </CardContent>
      </Card>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 2%;
  background-color: #f7f7f7;

  .left-container {
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .right-container {
    width: 30%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 1% 1% 1%;
  }

  .chart-container {
    position: relative;
    width: 48%;
    height: 100px;
    margin: 0 1% 1% 1%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    box-shadow: 0px 0px 4px 4px rgba(201, 201, 201, 0.1);
  }

  .lg {
    height: 100%;
  }

  .wide {
    height: 100%;
  }

  .data-container {
    width: 95%;
    padding: 20px;
    margin: 0 2.5% 10px 2.5%;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .data-header {
    padding-left: 15px;
    color: #666;
  }
  .data-value {
    padding-right: 15px;
    color: #666;
  }

  .header {
    padding-top: 20px;
  }

  .switch-container {
    position: absolute;
    right: 1%;
    top: 1%;
  }
`;

export default SalesComp;
