import axios from "axios";
import {
  signInWithCustomToken,
  sendPasswordResetEmail,
  getAuth,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { Alert } from "@mui/material";
import ReactDOM from "react-dom";
import { firebaseConfig } from "../constants/constantsFirebase";
import LocalCafeOutlinedIcon from "@mui/icons-material/LocalCafeOutlined";
import EmojiFoodBeverageOutlinedIcon from "@mui/icons-material/EmojiFoodBeverageOutlined";
import LocalBarOutlinedIcon from "@mui/icons-material/LocalBarOutlined";
import BakeryDiningOutlinedIcon from "@mui/icons-material/BakeryDiningOutlined";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import TakeoutDiningOutlinedIcon from "@mui/icons-material/TakeoutDiningOutlined";

const REACT_APP_API_ADDRESS = process.env.REACT_APP_API_ADDRESS;

export const checkUpdatedUserObject = async () => {
  const checkLoggedInUser = await loginUserByToken();
  // console.log("checkLoggedInUser", checkLoggedInUser);
  return checkLoggedInUser;
};

// Only works if the user has a valid token
export const loginUserByToken = async () => {
  try {
    let authToken = window.localStorage.getItem("auth_token");

    console.log("authToken:", authToken);
    if (!authToken) return {};

    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/login_user_by_token`,
      //prettier-ignore
      { "token": authToken }
    );

    console.log("resp from loginUserByToken", resp.data);

    if (
      // "acceptedInvitation" in resp.data &&
      "name" in resp.data &&
      "email" in resp.data &&
      "emailVerified" in resp.data &&
      "createdAt" in resp.data &&
      "updatedAt" in resp.data &&
      "deviceTokens" in resp.data &&
      "uid" in resp.data &&
      "token" in resp.data &&
      "firebaseToken" in resp.data
    ) {
      //show success alert
      return resp.data;
    } else {
      return {};
    }
  } catch (error) {
    console.log(error.message);
    alert("Something went wrong, please try again");
    return {};
  }
};

export const initialiseUser = async (
  acceptedInvitation,
  name,
  email,
  emailVerified,
  createdAt,
  updatedAt,
  deviceTokens,
  uid,
  token,
  firebaseToken,
  claims
) => {
  if (uid?.length > 0) {
    console.log("initialiseUser logging into Firebase");

    try {
      const app = initializeApp(firebaseConfig);
      console.log("Attempting firebase auth with token ", firebaseToken);
      const auth = getAuth();
      await signInWithCustomToken(auth, firebaseToken || "");

      const newState = {
        acceptedInvitation,
        name,
        email,
        emailVerified,
        createdAt,
        updatedAt,
        deviceTokens,
        uid,
        token,
        firebaseToken,
        claims,
      };

      console.log("initialiseUser returning", newState);

      return newState;
    } catch (error) {
      console.error(error);
      console.error(error.message);
    }
  }
};

export const sendResetPasswordEmail = async (email, setIsLoading) => {
  setIsLoading(true);
  console.log("auth:", sendPasswordResetEmail());

  try {
    const actionCodeSettings = {
      android: {
        installApp: true,
        packageName: "com.nudodesigns.nakedcoffeeadminapp",
      },
      handleCodeInApp: true,
      url: "https://nakedcoffee.co/fbrstoradddtls",
      // dynamicLinkDomain: "",
      iOS: {
        bundleId: "com.nudodesigns.nakedcoffeeadminapp",
      },
    };
    const resp = await sendPasswordResetEmail(email, actionCodeSettings);
    console.log("resp from calling sendPasswordResetEmail", resp);
    setIsLoading(false);
    alert("Successfully sent email");
  } catch (error) {
    console.log("error in sendResetPasswordEmail:", error);
    console.log("error in sendResetPasswordEmail:", error.message);
    setIsLoading(false);
    ReactDOM.render(
      <Alert severity="error">This is an error alert — check it out!</Alert>,
      document.getElementById("root")
    );
  }
};

// export const auditLog = async (message) => {
//   storeData = JSON.parse(storeData);
//   const storeToken = storeData?.store_token;

//   const timeStamp = new Date().toISOString();

//   queue.addJob(
//     WORKER_AUDIT,
//     JSON.stringify({
//       message: "(" + timeStamp + ") " + message,
//       store_token: storeToken,
//     }),
//     { timeout: 600000, attempts: 1 }
//   );
// };

export const fetchRegisteredStores = async () => {
  try {
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/fetch_registered_stores`,
      //prettier-ignore
      { "admin_token": authToken }
    );
    console.log("resp from fetchRegisteredStores:", resp.data);
    if (resp.data) {
      const stores = Object.values(resp.data);
      return stores;
    }
  } catch (error) {
    console.log(error.message);
    console.log(error);
    alert("Something went wrong when trying to fetch registered stores");
  }
};

export const getSalesData = async (
  storeId,
  setSalesData,
  setPieChartAmount,
  setPieChartNumber,
  setAll,
  setCard,
  setCash,
  setSplit,
  setPieChartAttributes,
  setClerkData,
  setPieChartProducts,
  timePeriod
) => {
  try {
    const startTimestamp = new Date(timePeriod?.startTimestamp).toISOString();
    const endTimestamp = new Date(timePeriod?.endTimestamp).toISOString();

    console.log("startTimestamp:", startTimestamp);
    console.log("endTimestamp:", endTimestamp);

    const authToken = window.localStorage.getItem("auth_token");

    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/get_sales`,
      //prettier-ignore
      { "token": authToken, "store_id": storeId, "start_timestamp": startTimestamp, "end_timestamp": endTimestamp }
    );

    setSalesData(resp.data);
    setPieChartAmount(resp.data?.pie_chart_amount);
    setPieChartNumber(resp.data?.pie_chart_number);

    // all for hourly sales
    if (
      resp?.data?.all_hourly_sales &&
      Object.keys(resp?.data?.all_hourly_sales).length > 0 &&
      Object.values(resp?.data?.all_hourly_sales).length > 0
    ) {
      setAll(Object.values(resp?.data?.all_hourly_sales));
    }

    if (
      resp?.data?.card_hourly_sales &&
      Object.keys(resp?.data?.card_hourly_sales).length > 0 &&
      Object.values(resp?.data?.card_hourly_sales).length > 0
    ) {
      setCard(Object.values(resp?.data?.card_hourly_sales));
    }
    if (
      resp?.data?.cash_hourly_sales &&
      Object.keys(resp?.data?.cash_hourly_sales).length > 0 &&
      Object.values(resp?.data?.cash_hourly_sales).length > 0
    ) {
      setCash(Object.values(resp?.data?.cash_hourly_sales));
    }

    if (
      resp?.data?.attribute_data &&
      Object.keys(resp?.data?.attribute_data).length > 0 &&
      Object.values(resp?.data?.attribute_data).length > 0
    ) {
      setPieChartAttributes(resp?.data?.attribute_data);
    }
    if (
      resp?.data?.clerk_data &&
      Object.keys(resp?.data?.clerk_data).length > 0 &&
      Object.values(resp?.data?.clerk_data).length > 0
    ) {
      setClerkData(Object.values(resp?.data?.clerk_data));
    }
    if (
      resp?.data?.product_data &&
      Object.keys(resp?.data?.product_data).length > 0 &&
      Object.values(resp?.data?.product_data).length > 0
    ) {
      setPieChartProducts(Object.values(resp?.data?.product_data));
    }
  } catch (error) {
    console.log(error.message);
    console.log(error);
    alert("Something went wrong when trying to get sales");
  }
};

export const getPreviousMonday = () => {
  var date = new Date();
  var day = date.getDay();
  var prevMonday = new Date();
  if (date.getDay() == 0) {
    prevMonday.setDate(date.getDate() - 7);
    prevMonday.setHours(0, 0, 0);
  } else {
    prevMonday.setDate(date.getDate() - (day - 1));
    prevMonday.setHours(0, 0, 0);
  }
  console.log("prevMonday:", prevMonday);
  console.log("prevMonday:", prevMonday.getTime());
  return prevMonday.getTime();
};

export const updateStoreDetails = async (
  storeDetails,
  setIsLoading,
  enqueueSnackbar,
  navigate
) => {
  let isValid = { valid: true, error: "" };
  if (storeDetails?.opening_times) {
    storeDetails?.opening_times.map((times) => {
      if (!times.open || !times.close) {
        isValid.error = "Missing opening times or close times";
        isValid.valid = false;
        return;
      }
      if (
        times.open.slice(-2) === "am" ||
        times.open.slice(-2) === "pm" ||
        times.open === "closed" ||
        times.close.slice(-2) === "am" ||
        times.close === "closed" ||
        times.close.slice(-2) === "pm"
      ) {
        //all good
        console.log("good");
      } else {
        isValid.error = "Opening Times are formatted incorrectly";
        isValid.valid = false;
      }
    });
  }

  if (!isValid?.valid) {
    enqueueSnackbar(isValid?.error, {
      variant: "error",
    });
    return;
  }

  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/update_store_details_for_location`,
      //prettier-ignore
      { "admin_token": authToken, 
      "location_id": storeDetails.locationId,
      "name": storeDetails.name,
      "email": storeDetails.email,
      "img": storeDetails.img,
      "directions": storeDetails.directions,
      "opening_times": storeDetails.openingTimes,
     }
    );
    console.log("resp from updateStoreData:", resp.data);
    setIsLoading(false);

    navigate(`/locations/${storeDetails.locationId}`);
    enqueueSnackbar(
      `Succesfully updated store details for ${storeDetails?.name}`,
      {
        variant: "success",
      }
    );
  } catch (error) {
    setIsLoading(false);

    console.log(error.message);
    console.log(error);
    enqueueSnackbar(
      `Something went wrong when trying to update store details`,
      {
        variant: "error",
      }
    );
  }
};

export const getStoreDetails = async (locationId, enqueueSnackbar) => {
  try {
    const authToken = window.localStorage.getItem("auth_token");
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/get_store_details_for_location`,
      //prettier-ignore
      { "admin_token": authToken, "location_id": locationId}
    );
    console.log("resp from getStoreDetails:", resp.data);
    return resp.data;
  } catch (error) {
    console.log(error.message);
    console.log(error);
    enqueueSnackbar(`Something went wrong when trying to get store details`, {
      variant: "error",
    });
    return false;
  }
};

export const registerNewStore = async (storeData, setIsLoading, navigate) => {
  let isValid = { valid: true, error: "" };
  // if (storeData?.opening_times) {
  //   storeData?.opening_times.map((times) => {
  //     if (!times.open || !times.close) {
  //       isValid.error = "Missing opening times or close times";
  //       isValid.valid = false;
  //       return;
  //     }
  //     if (
  //       times.open.slice(-2) === "am" ||
  //       times.open.slice(-2) === "pm" ||
  //       times.open === "closed" ||
  //       times.close.slice(-2) === "am" ||
  //       times.close === "closed" ||
  //       times.close.slice(-2) === "pm"
  //     ) {
  //       //all good
  //       console.log("good");
  //     } else {
  //       isValid.error = "Opening Times are formatted incorrectly";
  //       isValid.valid = false;
  //     }
  //   });
  // }

  // if (!isValid?.valid) {
  //   alert(isValid?.error);
  //   return;
  // }

  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/register_new_store`,
      //prettier-ignore
      { "admin_token": authToken, "store_name": storeData.store_name, "store_id": storeData.store_id, "latitude": storeData.latitude, "longitude": storeData.longitude, "store_pin": storeData.store_pin }
    );
    console.log("resp from updateStoreData:", resp.data);

    navigate("/registeredstores");
    alert(`Succesfully registered ${storeData?.store_id}`);
  } catch (error) {
    navigate("/registeredstores");
    console.log(error.message);
    console.log(error);
    alert("Something went wrong when trying to create store");
  }
};

export const uploadProductsFile = async (formData) => {
  try {
    const config = {
      headers: {
        "Content-type": "multipart/form-data",
      },
    };
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/upload_products`,
      //prettier-ignore
      formData,
      config
    );
    console.log("resp from uploadProductsFile:", resp.data);
  } catch (error) {
    console.log(error.message);
    console.log(error);
    alert("Something went wrong when trying to upload file");
  }
};

export const uploadMenu = async (formData, enqueueSnackbar) => {
  try {
    const config = {
      headers: {
        "Content-type": "multipart/form-data",
      },
    };
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/upload_menus`,
      //prettier-ignore
      formData,
      config
    );
    console.log("resp from uploadMenu:", resp.data);
    return true;
  } catch (error) {
    console.log(error.message);
    console.log(error);
    enqueueSnackbar("Something went wrong when trying to upload menu", {
      variant: "error",
    });
    return false;
  }
};

export const getMenus = async (enqueueSnackbar) => {
  try {
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/get_menus`,
      //prettier-ignore
      { "admin_token": authToken }
    );
    console.log("resp from getMenus:", resp.data);
    return resp.data;
  } catch (error) {
    console.log(error.message);
    console.log(error);
    enqueueSnackbar(`Something went wrong when trying to get menus`, {
      variant: "error",
    });
    return false;
  }
};

export const fetchProducts = async (
  setProducts,
  enqueueSnackbar,
  setIsLoading
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/view_products`,
      //prettier-ignore
      { "admin_token": authToken }
    );
    console.log("resp from fetchProducts:", resp.data);
    setProducts(resp.data);
    setIsLoading(false);
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(`Something went wrong when trying to fetch products`, {
      variant: "error",
    });
  }
};

export const fetchEposGroups = async (setEposGroups) => {
  try {
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/get_epos_groups`,
      //prettier-ignore
      { "admin_token": authToken }
    );
    console.log("resp from fetchEposGroups:", resp.data);
    setEposGroups(resp.data);
  } catch (error) {
    console.log(error.message);
    console.log(error);
    alert(`Something went wrong when trying to fetch epos groups`);
  }
};
export const fetchOptions = async (
  setOptionGroups,
  setIsLoading,
  enqueueSnackbar
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/get_option_groups`,
      //prettier-ignore
      { "admin_token": authToken }
    );
    console.log("resp from fetchOptions:", resp.data);
    setOptionGroups(resp.data);
    setIsLoading(false);
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(`Something went wrong when trying to fetch options`, {
      variant: "error",
    });
  }
};

export const fetchSingleCategory = async (
  categoryId,
  setProduct,
  setIsLoading
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/get_a_category`,
      //prettier-ignore
      { "admin_token": authToken, "category_id": categoryId }
    );
    console.log("resp from fetchSingleCategory:", resp.data);
    setProduct(resp.data);
    setIsLoading(false);
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    alert(`Something went wrong when trying to fetch a category`);
  }
};

export const getAproduct = async (
  productId,
  setProduct,
  setRawProduct,
  setIsLoading,
  enqueueSnackbar
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/get_a_product`,
      //prettier-ignore
      { "admin_token": authToken, "product_id": productId }
    );
    console.log("resp from getAproduct:", resp.data.variations);
    const formattedProduct = formatProductResp(resp.data);
    setProduct(formattedProduct);
    setRawProduct(JSON.parse(JSON.stringify(formattedProduct)));
    setIsLoading(false);
  } catch (error) {
    console.log(error.message);
    console.log(error);
    enqueueSnackbar(`Something went wrong when trying to get a product`, {
      variant: "error",
    });
    setIsLoading(false);
  }
};

export const addOption = async (
  optionData,
  setIsLoading,
  setOptions,
  enqueueSnackbar
) => {
  try {
    console.log("optionData:", optionData);
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/add_option`,
      //prettier-ignore
      { "admin_token": authToken, "option_group": optionData}
    );
    console.log("resp from addOption:", resp.data);
    setOptions(resp.data);
    setIsLoading(false);
    enqueueSnackbar(`Successfully added option`, { variant: "success" });
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(`Something went wrong when trying to add option`, {
      variant: "error",
    });
  }
};

export const updateOption = async (
  optionData,
  setIsLoading,
  setOptions,
  enqueueSnackbar
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/update_option`,
      //prettier-ignore
      { "admin_token": authToken, "option_group": optionData}
    );
    console.log("resp from addOption:", resp.data);
    setOptions(resp.data);
    setIsLoading(false);
    enqueueSnackbar(`Successfully updated option`, { variant: "success" });
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    alert(`Something went wrong when trying to update option`);
  }
};

export const addProduct = async (productData, enqueueSnackbar) => {
  try {
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/add_product`,
      //prettier-ignore
      { "admin_token": authToken, "product_data": productData }
    );
    console.log("resp from addProduct:", resp.data);
    return resp.data;
  } catch (error) {
    console.log(error.message);
    console.log(error);
    enqueueSnackbar(`Something went wrong when trying to add product`, {
      variant: "error",
    });
    return false;
  }
};

export const deleteProduct = async (productId, enqueueSnackbar) => {
  try {
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/delete_a_product`,
      //prettier-ignore
      { "admin_token": authToken, "product_id": productId}
    );
    console.log("resp from deleteProduct:", resp.data);
    return resp.data;
  } catch (error) {
    console.log(error.message);
    console.log(error);
    enqueueSnackbar(`Something went wrong when trying to delete products`, {
      variant: "error",
    });
    return false;
  }
};

export const deleteOption = async (
  optionId,
  setOptions,
  setIsLoading,
  enqueueSnackbar
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/delete_option`,
      //prettier-ignore
      { "admin_token": authToken, "option_id": optionId }
    );
    console.log("resp from deleteOption:", resp.data);
    setOptions(resp.data);
    setIsLoading(false);
    enqueueSnackbar("Successfully deleted option", { variant: "success" });
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(`Something went wrong when trying to delete option`, {
      variant: "error",
    });
  }
};

export const getTaxGroups = async (
  setTaxGroups,
  setIsLoading,
  enqueueSnackbar
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/get_tax_groups`,
      //prettier-ignore
      { "admin_token": authToken}
    );
    console.log("resp from getTaxGroups:", resp.data);
    setIsLoading(false);
    setTaxGroups(resp?.data);
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(`Something went wrong when trying to get tax groups`, {
      variant: "error",
    });
  }
};

export const createNewTaxGroup = async (newTaxGroupValue, enqueueSnackbar) => {
  try {
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/create_new_tax_group`,
      //prettier-ignore
      { "admin_token": authToken, "tax_rate": newTaxGroupValue.taxRate, "name": newTaxGroupValue.name}
    );
    console.log("resp from createNewTaxGroup:", resp.data);
    return resp.data;
  } catch (error) {
    console.log(error.message);
    console.log(error);
    enqueueSnackbar(
      `Something went wrong when trying to create new tax group`,
      {
        variant: "error",
      }
    );
    return false;
  }
};

export const addProductOptions = async (
  productId,
  selectedOptions,
  setProduct,
  setIsLoading,
  enqueueSnackbar
) => {
  try {
    console.log("selectedOptions:", selectedOptions);
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/add_options_to_product`,
      //prettier-ignore
      { "admin_token": authToken, "product_id": productId, "selected_options": selectedOptions }
    );
    console.log("resp from addProductOptions:", resp.data);
    const formattedProduct = formatProductResp(resp.data);
    setProduct(formattedProduct);
    setIsLoading(false);
    enqueueSnackbar("Successfully added product options", {
      variant: "success",
    });
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(`Something went wrong when trying to add product options`, {
      variant: "error",
    });
  }
};

export const getProductOptions = async (
  productId,
  setProductOptions,
  setIsLoading,
  enqueueSnackbar
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/get_product_options`,
      //prettier-ignore
      { "admin_token": authToken, "product_id": productId }
    );
    console.log("resp from getProductOptions:", resp.data);
    setProductOptions(resp.data);
    setIsLoading(false);
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(
      `Something went wrong when trying to fetch product options`,
      {
        variant: "error",
      }
    );
  }
};

export const updateProductOption = async (
  productId,
  updatedOption,
  enqueueSnackbar
) => {
  try {
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/update_product_option`,
      //prettier-ignore
      { "admin_token": authToken, "product_id": productId, "updated_option": updatedOption, "option_index": updatedOption.index }
    );
    console.log("resp from updateProductOptions:", resp.data);
    const formattedProduct = formatProductResp(resp.data);
    enqueueSnackbar("Successfully updated product option", {
      variant: "success",
    });
    return formattedProduct;
  } catch (error) {
    console.log(error.message);
    console.log(error);
    enqueueSnackbar(
      `Something went wrong when trying to update product option`,
      {
        variant: "error",
      }
    );
    return false;
  }
};

export const updateProduct = async (
  updatedProduct,
  setRawProduct,
  setProduct,
  setIsLoading,
  enqueueSnackbar
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/update_product`,
      //prettier-ignore
      { "admin_token": authToken, "updated_product": updatedProduct }
    );
    console.log("resp from updateProduct:", resp.data);
    const formattedProduct = formatProductResp(resp.data);
    setRawProduct(JSON.parse(JSON.stringify(formattedProduct)));
    setProduct(formattedProduct);
    setIsLoading(false);
    enqueueSnackbar("Successfully updated product", {
      variant: "success",
    });
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(`Something went wrong when trying to update product`, {
      variant: "error",
    });
  }
};

export const updateProductOptionsDisplayOrder = async (
  productId,
  updatedOptions,
  setProduct,
  setIsLoading,
  enqueueSnackbar
) => {
  try {
    updatedOptions.map((option) => {
      option.optionItems.map((item) => {
        if (item?.priceModifierType !== "PERCENT") {
          item.priceModifier = Math.round(Number(item.priceModifier) * 100);
        }
      });
    });

    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/update_product_options_display_order`,
      //prettier-ignore
      { "admin_token": authToken, "product_id": productId, "updated_options": updatedOptions }
    );
    console.log("resp from updateProductOptions:", resp.data);
    const formattedProduct = formatProductResp(resp.data);
    setProduct(formattedProduct);
    setIsLoading(false);
    enqueueSnackbar("Successfully updated product options display order", {
      variant: "success",
    });
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(
      `Something went wrong when trying to update product options display order`,
      {
        variant: "error",
      }
    );
  }
};

export const removeOptionGroupFromProduct = async (
  productId,
  optionId,
  optionIndex,
  enqueueSnackbar
) => {
  try {
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/remove_option_group_from_product`,
      //prettier-ignore
      { "admin_token": authToken, "product_id": productId, "option_index": optionIndex, "option_id": optionId }
    );
    console.log("resp from removeOptionGroupFromProduct:", resp.data);
    return resp.data;
  } catch (error) {
    console.log(error.message);
    console.log(error);
    enqueueSnackbar(
      `Something went wrong when trying to remove option group from product`,
      {
        variant: "error",
      }
    );
    return false;
  }
};

export const removeOptionFromProduct = async (
  productId,
  optionId,
  optionIndex,
  setProduct,
  setIsLoading,
  enqueueSnackbar
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/remove_option_from_product`,
      //prettier-ignore
      { "admin_token": authToken, "product_id": productId, "option_id": optionId, "option_index": optionIndex }
    );
    console.log("resp from removeOptionGroupFromProduct:", resp.data);
    const formattedProduct = formatProductResp(resp.data);
    setProduct(formattedProduct);
    setIsLoading(false);
    enqueueSnackbar("Successfully removed option from product", {
      variant: "success",
    });
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(
      `Something went wrong when trying to remove option from product`,
      {
        variant: "error",
      }
    );
  }
};

export const addProductVariation = async (
  productId,
  variation,
  enqueueSnackbar
) => {
  try {
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/add_product_variation`,
      //prettier-ignore
      { "admin_token": authToken, "product_id": productId, "variation": variation }
    );
    console.log("resp from addProductVariation:", resp.data);
    enqueueSnackbar("Successfully added variation", {
      variant: "success",
    });
    const formattedProduct = formatProductResp(resp.data);
    return formattedProduct;
  } catch (error) {
    console.log(error.message);
    console.log(error);
    enqueueSnackbar(`Something went wrong when trying to add variation`, {
      variant: "error",
    });
    return null;
  }
};

export const deleteProductVariation = async (
  productId,
  variation,
  enqueueSnackbar
) => {
  try {
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/delete_product_variation`,
      //prettier-ignore
      { "admin_token": authToken, "product_id": productId, "variation": variation }
    );
    console.log("resp from deleteProductVariation:", resp.data);
    return resp.data;
  } catch (error) {
    console.log(error.message);
    console.log(error);
    enqueueSnackbar(`Something went wrong when trying to remove variation`, {
      variant: "error",
    });
    return null;
  }
};

export const updateProductVariation = async (
  productId,
  originalVariationId,
  variation,
  enqueueSnackbar
) => {
  try {
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/update_product_variation`,
      //prettier-ignore
      { "admin_token": authToken, "product_id": productId, "original_variation_id": originalVariationId, "updated_variation": variation }
    );
    console.log("resp from updateProductVariation:", resp.data);
    enqueueSnackbar("Successfully updated variation", {
      variant: "success",
    });
    const formattedProduct = formatProductResp(resp.data);
    return formattedProduct;
  } catch (error) {
    console.log(error.message);
    console.log(error);
    enqueueSnackbar(`Something went wrong when trying to update variation`, {
      variant: "error",
    });
    return null;
  }
};
export const createEposGroup = async (eposGroupName, enqueueSnackbar) => {
  try {
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/create_epos_group`,
      //prettier-ignore
      { "admin_token": authToken, "epos_group_name": eposGroupName, }
    );
    console.log("resp from createEposGroup:", resp.data);
    enqueueSnackbar("Successfully created epos group", {
      variant: "success",
    });
    return resp.data;
  } catch (error) {
    console.log(error.message);
    console.log(error);
    enqueueSnackbar(`Something went wrong when trying to create epos group`, {
      variant: "error",
    });
    return null;
  }
};

export const createEposGroupFromCopy = async (
  currEposGroupId,
  newTillGroupName,
  setEposGroups,
  setIsLoading,
  enqueueSnackbar
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/create_epos_group_from_copy`,
      //prettier-ignore
      { "admin_token": authToken, "curr_epos_group_id": currEposGroupId, "new_epos_group_name": newTillGroupName }
    );
    console.log("resp from createEposGroupFromCopy:", resp.data);
    setEposGroups(resp.data);
    setIsLoading(false);
    enqueueSnackbar("Successfully created new epos group from copy", {
      variant: "success",
    });
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(
      `Something went wrong when trying to create new epos group from copy`,
      {
        variant: "error",
      }
    );
  }
};

export const addCategoryToEposGroup = async (
  eposGroupId,
  itemName,
  screenId,
  setSelectedGroup,
  setIsLoading,
  enqueueSnackbar,
  currScreen,
  setCurrScreen
) => {
  try {
    if (currScreen?.items.length === 9) {
      enqueueSnackbar(`You can only have 9 items per screen`, {
        variant: "warning",
      });
      return;
    }
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/add_category_to_epos_group`,
      //prettier-ignore
      { "admin_token": authToken, "epos_group_id": eposGroupId, "item_name": itemName, "screen_id": screenId }
    );
    console.log("resp from addCategoryToEposGroup:", resp.data);
    const updatedSelectedGroup = resp.data;
    setCurrScreen({
      items: updatedSelectedGroup?.items[screenId]?.displayOrder,
      itemId: screenId,
      itemLabel: screenId,
      // itemLabel: itemLabel,
    });

    setSelectedGroup(resp.data);
    setIsLoading(false);
    enqueueSnackbar("Successfully added category to epos group", {
      variant: "success",
    });
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(
      `Something went wrong when trying to add category to epos group`,
      {
        variant: "error",
      }
    );
  }
};

export const removeItemFromEposGroupScreen = async (
  eposGroupId,
  itemId,
  screenId,
  setSelectedGroup,
  setCurrScreen,
  setIsLoading,
  enqueueSnackbar
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/remove_item_from_epos_group_screen`,
      //prettier-ignore
      { "admin_token": authToken, "epos_group_id": eposGroupId, "item_id": itemId, "screen_id": screenId, }
    );
    console.log("resp from removeItemFromEposGroupScreen:", resp.data);
    if (resp?.data?.deletedScreenAswell) {
      enqueueSnackbar(
        `${itemId} screen was deleted as no screens serve this screen`,
        {
          variant: "info",
        }
      );
    }

    const updatedCurrScreen = updateCurrScreen(
      resp?.data?.updatedEposGroup,
      screenId
    );
    setCurrScreen(updatedCurrScreen);
    setSelectedGroup(resp?.data?.updatedEposGroup);
    setIsLoading(false);
    enqueueSnackbar("Successfully removed category from epos group", {
      variant: "success",
    });
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(
      `Something went wrong when trying to remove category from epos group`,
      {
        variant: "error",
      }
    );
  }
};

export const addShortcutBtn = async (
  eposGroupId,
  iconName,
  productId,
  setSelectedGroup,
  setIsLoading,
  enqueueSnackbar
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/add_shortcut_btn_to_epos_group`,
      //prettier-ignore
      { "admin_token": authToken, "epos_group_id": eposGroupId, "icon_name": iconName, "product_id": productId, }
    );
    console.log("resp from addShortcutBtn:", resp.data);

    setSelectedGroup(resp?.data);
    setIsLoading(false);
    enqueueSnackbar("Successfully added shortcut button to epos group", {
      variant: "success",
    });
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(
      `Something went wrong when trying to add shortcut button to epos group`,
      {
        variant: "error",
      }
    );
  }
};

export const deleteShortcutBtn = async (
  eposGroupId,
  productId,
  setSelectedGroup,
  setIsLoading,
  enqueueSnackbar
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/delete_shortcut_btn_from_epos_group`,
      //prettier-ignore
      { "admin_token": authToken, "epos_group_id": eposGroupId, "product_id": productId, }
    );
    console.log("resp from deleteShortcutBtn:", resp.data);

    setSelectedGroup(resp?.data);
    setIsLoading(false);
    enqueueSnackbar("Successfully deleted shortcut button from epos group", {
      variant: "success",
    });
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(
      `Something went wrong when trying to delete shortcut button from epos group`,
      {
        variant: "error",
      }
    );
  }
};

export const showScreensUsingAScreen = async (
  eposGroupId,
  itemId,
  enqueueSnackbar
) => {
  try {
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/show_screens_using_a_screen`,
      //prettier-ignore
      { "admin_token": authToken, "epos_group_id": eposGroupId, "item_id": itemId }
    );
    console.log("resp from showScreensUsingAScreen:", resp.data);
    return resp.data;
  } catch (error) {
    console.log(error.message);
    console.log(error);
    enqueueSnackbar(
      `Something went wrong when trying to remove screen from epos group`,
      {
        variant: "error",
      }
    );
    return false;
  }
};

export const updateDisplayOrder = async (
  eposGroupId,
  screenId,
  updatedDisplayOrder,
  setSelectedGroup,
  setIsLoading,
  enqueueSnackbar
) => {
  try {
    console.log("updatedDisplayOrder:", updatedDisplayOrder);
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/update_display_order`,
      //prettier-ignore
      { "admin_token": authToken, "epos_group_id": eposGroupId, "screen_id": screenId, "updated_display_order": updatedDisplayOrder }
    );
    console.log("resp from updateDisplayOrder:", resp.data);
    const selectedGroup = resp.data;
    setSelectedGroup(resp.data);
    setIsLoading(false);
    enqueueSnackbar("Successfully updated display order", {
      variant: "success",
    });
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(
      `Something went wrong when trying to update display order`,
      {
        variant: "error",
      }
    );
  }
};
export const getTillProducts = async (
  eposGroupName,
  setIsLoading,
  enqueueSnackbar
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/get_till_products`,
      //prettier-ignore
      { "admin_token": authToken, "epos_group_id": eposGroupName }
    );
    console.log("resp from getTillProducts:", resp.data);
    setIsLoading(false);
    enqueueSnackbar("Successfully fetched till products", {
      variant: "success",
    });
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(`Something went wrong when trying to fetch till products`, {
      variant: "error",
    });
  }
};

export const addProductsToEposGroup = async (
  eposGroupId,
  screenId,
  products,
  setSelectedGroup,
  setCurrScreen,
  setIsLoading,
  enqueueSnackbar
) => {
  try {
    // if (currScreen?.items.length === 9) {
    //   enqueueSnackbar(`You can only have 9 items per screen`, {
    //     variant: "warning",
    //   });
    //   return;
    // }
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/add_products_to_epos_group`,
      //prettier-ignore
      { "admin_token": authToken, "epos_group_id": eposGroupId, "screen_id": screenId, "products": products }
    );
    console.log("resp from addProductsToEposGroup:", resp.data);
    const updatedCurrScreen = updateCurrScreen(resp.data, screenId);
    setCurrScreen(updatedCurrScreen);

    setSelectedGroup(resp.data);
    setIsLoading(false);
    enqueueSnackbar("Successfully added products to epos group", {
      variant: "success",
    });
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(
      `Something went wrong when trying to add products to epos group`,
      {
        variant: "error",
      }
    );
  }
};

export const getStaff = async (setIsLoading, enqueueSnackbar) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/get_staff`,
      //prettier-ignore
      { "admin_token": authToken }
    );
    console.log("resp from getStaff:", resp.data);
    setIsLoading(false);
    return resp.data;
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(`Something went wrong when trying to get staff`, {
      variant: "error",
    });
  }
};

export const getAStaffUser = async (uid, setIsLoading, enqueueSnackbar) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/get_a_staff_user`,
      //prettier-ignore
      { "admin_token": authToken, "uid": uid }
    );
    console.log("resp from getAStaffUser:", resp.data);
    setIsLoading(false);
    return resp.data;
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(`Something went wrong when trying to get a staff user`, {
      variant: "error",
    });
  }
};

export const getEposUsers = async (setUsers, setIsLoading, enqueueSnackbar) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/get_epos_users`,
      //prettier-ignore
      { "admin_token": authToken }
    );
    console.log("resp from getEposUsers:", resp.data);
    setUsers(resp.data);
    setIsLoading(false);
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(`Something went wrong when trying to get till users`, {
      variant: "error",
    });
  }
};

export const createEposUser = async (
  eposUser,
  setUsers,
  setIsLoading,
  enqueueSnackbar,
  oldUsersPin = null
) => {
  console.log("oldUsersPin:", oldUsersPin);

  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/create_epos_user`,
      //prettier-ignore
      { "admin_token": authToken, "epos_user":eposUser, "old_epos_user_pin": oldUsersPin}
    );
    console.log("resp from createEposUser:", resp.data);
    setUsers(resp.data);
    setIsLoading(false);
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(`Something went wrong when trying to create epos user`, {
      variant: "error",
    });
  }
};

export const deleteEposUser = async (
  eposUserPin,
  setUsers,
  setIsLoading,
  enqueueSnackbar
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/delete_epos_user`,
      //prettier-ignore
      { "admin_token": authToken, "epos_user_pin":eposUserPin}
    );
    console.log("resp from deleteEposUser:", resp.data);
    setUsers(resp.data);
    setIsLoading(false);
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(`Something went wrong when trying to delete epos user`, {
      variant: "error",
    });
  }
};

export const getCustomers = async (enqueueSnackbar, lastCustomer = false) => {
  try {
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/get_customers`,
      //prettier-ignore
      { "admin_token": authToken, "last_item": lastCustomer }
    );
    console.log("resp from getCustomers:", resp.data);
    return resp.data;
  } catch (error) {
    console.log(error.message);
    console.log(error);
    enqueueSnackbar(`Something went wrong when trying to get customers`, {
      variant: "error",
    });
  }
};

export const sendNotificationToTill = async (enqueueSnackbar) => {
  try {
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/send_notification_to_till`,
      //prettier-ignore
      { "admin_token": authToken, "store_id": "NKD-STORE-TESTT", "device_name": "NKD-STORE-TESTT-marcos-ipad-1" }
    );
    console.log("resp from getUsers:", resp.data);
  } catch (error) {
    console.log(error.message);
    console.log(error);
    enqueueSnackbar(`Something went wrong when trying to get employees`, {
      variant: "error",
    });
  }
};

const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name) => {
  let initials = "";
  let splitName = name.split(" ");
  if (splitName.length === 1 || splitName.length > 2) {
    initials = name[0];
  } else {
    initials = splitName[0][0] + splitName[1][0];
  }
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: initials,
  };
};

export const createStockArray = async () => {
  try {
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/check_if_device_registered`,
      //prettier-ignore
      { key: "create_stock20031973", device_id: "", manufacturer: "" }
    );
    console.log("resp from fetchProducts:", resp.data);
  } catch (error) {
    console.log(error.message);
    console.log(error);
    alert(`Something went wrong when trying to fetch products`);
  }
};

export const createStoreGroup = async (
  storeGroupData,
  setIsLoading,
  setStoreGroups,
  enqueueSnackbar
) => {
  try {
    console.log("storeGroupData:", storeGroupData);
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/create_store_group`,
      //prettier-ignore
      { "admin_token": authToken, "store_group_data": storeGroupData}
    );
    console.log("resp from createStoreGroup:", resp.data);
    setStoreGroups(resp.data);
    setIsLoading(false);
    enqueueSnackbar(`Successfully created store group`, { variant: "success" });
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(`Something went wrong when trying to create store group`, {
      variant: "error",
    });
  }
};

export const updateStoreGroup = async (
  storeGroupData,
  setIsLoading,
  setStoreGroups,
  enqueueSnackbar
) => {
  try {
    console.log("storeGroupData:", storeGroupData);
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/update_store_group`,
      //prettier-ignore
      { "admin_token": authToken, "store_group_data": storeGroupData}
    );
    console.log("resp from updateStoreGroup:", resp.data);
    setStoreGroups(resp.data);
    setIsLoading(false);
    enqueueSnackbar(`Successfully updated store group`, { variant: "success" });
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(`Something went wrong when trying to update store group`, {
      variant: "error",
    });
  }
};

export const deleteStoreGroup = async (
  storeGroupId,
  setIsLoading,
  setStoreGroups,
  enqueueSnackbar
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/delete_store_group`,
      //prettier-ignore
      { "admin_token": authToken, "store_group_id": storeGroupId}
    );
    console.log("resp from deleteStoreGroup:", resp.data);
    setStoreGroups(resp.data);
    setIsLoading(false);
    enqueueSnackbar(`Successfully deleted store group`, { variant: "success" });
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(`Something went wrong when trying to deleted store group`, {
      variant: "error",
    });
  }
};

export const getStoreGroups = async (
  setStoreGroups,
  setIsLoading,
  enqueueSnackbar
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/get_store_groups`,
      //prettier-ignore
      { "admin_token": authToken}
    );
    console.log("resp from getStoreGroups:", resp.data);
    setStoreGroups(resp.data);
    setIsLoading(false);
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(`Something went wrong when trying to get store groups`, {
      variant: "error",
    });
  }
};

export const arrayContainsObject = (obj, list) => {
  return list.some(function (elem) {
    return elem?.locationId === obj.locationId;
  });
};

export const getWarehouses = async (
  setWarehouses,
  setIsLoading,
  enqueueSnackbar
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/get_warehouses`,
      //prettier-ignore
      { "admin_token": authToken}
    );
    console.log("resp from getWarehouses:", resp.data);
    setWarehouses(resp.data);
    setIsLoading(false);
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(`Something went wrong when trying to get warehouses`, {
      variant: "error",
    });
  }
};

export const getLocations = async (
  setLocations,
  setIsLoading,
  enqueueSnackbar,
  currentUser
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/get_locations`,
      //prettier-ignore
      { "admin_token": authToken}
    );
    console.log("resp from getLocations:", resp.data);

    if (currentUser?.claims?.userRoles?.includes("staff")) {
      const filteredLocations = resp.data.filter((location) => {
        console.log("lcoation:", location);
        return currentUser?.claims?.userStores?.includes(location.locationId);
      });
      setLocations(filteredLocations);
    } else {
      setLocations(resp.data);
    }
    setIsLoading(false);
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(`Something went wrong when trying to get locations`, {
      variant: "error",
    });
  }
};

export const createWarehouse = async (
  warehouseData,
  setWarehouses,
  setIsLoading,
  enqueueSnackbar
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/create_warehouse`,
      //prettier-ignore
      { "admin_token": authToken, "warehouse_data": warehouseData}
    );
    console.log("resp from createWarehouse:", resp.data);
    setWarehouses(resp.data);
    setIsLoading(false);
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(`Something went wrong when trying to create warehouse`, {
      variant: "error",
    });
  }
};

export const getALocation = async (
  locationId,
  setLocation,
  setIsLoading,
  enqueueSnackbar
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/get_a_location`,
      //prettier-ignore
      { "admin_token": authToken, "location_id": locationId}
    );
    console.log("resp from getALocation:", resp.data);
    setLocation(resp.data);
    setIsLoading(false);
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(`Something went wrong when trying to get location data`, {
      variant: "error",
    });
  }
};

export const createUser = async (userData, manageStores, roles) => {
  try {
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/create_user`,
      //prettier-ignore
      { "admin_token": authToken, "user_data": userData, user_stores: manageStores, user_roles: roles}
    );
    console.log("resp from createUser:", resp.data);
    return resp.data;
  } catch (error) {
    console.log(error.message);
    console.log(error);
    return false;
  }
};

export const getAWarehouse = async (
  locationId,
  setWarehouse,
  setIsLoading,
  enqueueSnackbar
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/get_a_warehouse`,
      //prettier-ignore
      { "admin_token": authToken, "location_id": locationId}
    );
    console.log("resp from getAWarehouse:", resp.data);
    setWarehouse(resp.data);
    setIsLoading(false);
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(`Something went wrong when trying to get warehouse data`, {
      variant: "error",
    });
  }
};

export const getGlobalSkuData = async (
  setSkuStockData,
  setIsLoading,
  enqueueSnackbar
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/get_global_sku_data`,
      //prettier-ignore
      { "admin_token": authToken,"product_type": "plu" }
    );
    console.log("resp from getGlobalSkuStockData:", resp.data);
    setSkuStockData(resp.data);
    setIsLoading(false);
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(
      `Something went wrong when trying to get global stock data`,
      {
        variant: "error",
      }
    );
  }
};
export const addPluToGlobalSku = async (
  sku,
  plu,
  setIsLoading,
  setHasChanged,
  enqueueSnackbar
) => {
  try {
    setIsLoading(true);
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/add_plu_to_global_sku`,
      //prettier-ignore
      { "admin_token": authToken, "sku": sku, "plu": plu }
    );
    console.log("resp from addPluToGlobalSku:", resp.data);
    setHasChanged(false);
    setIsLoading(false);
  } catch (error) {
    console.log(error.message);
    console.log(error);
    setIsLoading(false);
    enqueueSnackbar(`Something went wrong when trying to update plu`, {
      variant: "error",
    });
  }
};

export const generateReadableSkuString = (skuData) => {
  console.log("skuData:", skuData);
  let string = `${skuData?.productName}`;

  let i = 0;

  if (skuData?.options) {
    console.log("skuData:", skuData);
    for (let opt of skuData?.options) {
      if (opt?.optionId !== "storeGroup") {
        console.log("opt:", opt);
        if (i === skuData?.options.length - 1) {
          string += `(${opt?.optionName?.toLowerCase()}:${opt?.optionItem?.toLowerCase()})`;
        } else {
          string += `(${opt?.optionName?.toLowerCase()}:${opt?.optionItem?.toLowerCase()} / `;
        }
      }

      i++;
    }
  }

  return string;
};

export const validateNewProductFields = (formValue, enqueueSnackbar) => {
  console.log("formValue:", formValue);
  if (formValue.productName.length === 0) {
    enqueueSnackbar(`Invalid Product Name`, {
      variant: "error",
    });
    return false;
  }
  if (formValue.takeawayPrice.length === 0) {
    enqueueSnackbar(`Invalid Takeaway Price`, {
      variant: "error",
    });
    return false;
  }
  if (formValue.eatInPrice.length === 0) {
    enqueueSnackbar(`Invalid Eat In Price`, {
      variant: "error",
    });
    return false;
  }

  if (formValue.unitCost.length === 0) {
    enqueueSnackbar(`Invalid Unit Cost`, {
      variant: "error",
    });
    return false;
  }

  if (formValue.attributes[0].value.length === 0) {
    enqueueSnackbar(`Invalid Product category attribute`, {
      variant: "error",
    });
    return false;
  }

  if (formValue.eatInTaxGroup.length === 0) {
    enqueueSnackbar(`Invalid Eat In tax group`, {
      variant: "error",
    });
    return false;
  }
  if (formValue.takeawayTaxGroup.length === 0) {
    enqueueSnackbar(`Invalid Takeaway tax group`, {
      variant: "error",
    });
    return false;
  }
  return true;
};

export const formatProductResp = (product) => {
  if (product) {
    const updatedProduct = { ...product };
    updatedProduct.unitCost = (updatedProduct.unitCost / 100).toFixed(2);
    updatedProduct.eatInPrice = (updatedProduct.eatInPrice / 100).toFixed(2);
    updatedProduct.takeawayPrice = (updatedProduct.takeawayPrice / 100).toFixed(
      2
    );

    if (updatedProduct?.productOptions) {
      updatedProduct.productOptions.map((option) => {
        option.optionItems.map((item) => {
          if (item?.priceModifierType !== "PERCENT") {
            item.priceModifier = (item.priceModifier / 100).toFixed(2);
          }
        });
      });
    }

    if (updatedProduct?.variations) {
      updatedProduct.variations.map((variation) => {
        variation.eatInPrice = (variation.eatInPrice / 100).toFixed(2);
        variation.takeawayPrice = (variation.takeawayPrice / 100).toFixed(2);
      });
    }

    console.log("formatProductResp, updatedProduct", updatedProduct);
    return updatedProduct;
  }
};

export const formatProductForRequest = (product) => {
  if (product) {
    const updatedProduct = { ...product };

    updatedProduct.unitCost = Math.round(Number(updatedProduct.unitCost) * 100);
    updatedProduct.eatInPrice = Math.round(
      Number(updatedProduct.eatInPrice) * 100
    );
    updatedProduct.takeawayPrice = Math.round(
      Number(updatedProduct.takeawayPrice) * 100
    );

    if (updatedProduct?.productOptions) {
      updatedProduct.productOptions.map((option) => {
        option.optionItems.map((item) => {
          if (item?.priceModifierType !== "PERCENT") {
            item.priceModifier = Math.round(Number(item.priceModifier) * 100);
          }
        });
      });
    }

    if (updatedProduct?.variations) {
      updatedProduct.variations.map((variation) => {
        variation.eatInPrice = Math.round(Number(variation.eatInPrice) * 100);
        variation.takeawayPrice = Math.round(
          Number(variation.takeawayPrice) * 100
        );
      });
    }

    return updatedProduct;
  }
};

export const updateCurrScreen = (updatedSelectedGroup, screenId) => {
  const updatedCurrScreen = {
    items: updatedSelectedGroup?.items[screenId]?.displayOrder,
    itemId: screenId,
    itemLabel: screenId.replace("_", " "),
    // itemLabel: itemLabel,
  };
  return updatedCurrScreen;
};

export const getUserRoles = async () => {
  const auth = getAuth();
  const tokenResult = await auth.currentUser.getIdTokenResult();
  console.log("getUserRoles tokenResult: ", tokenResult);

  if (tokenResult && tokenResult?.claims?.userRoles) {
    console.log("getUserRoles returning :", tokenResult?.claims?.userRoles);
    return tokenResult?.claims?.userRoles;
  } else {
    return [];
  }
};
export const getUserStores = async () => {
  const auth = getAuth();
  const tokenResult = await auth.currentUser.getIdTokenResult();
  console.log("getUserStores tokenResult: ", tokenResult);

  if (tokenResult && tokenResult?.claims?.userStores) {
    console.log("getUserStores returning :", tokenResult?.claims?.userStores);
    return tokenResult?.claims?.userStores;
  } else {
    return [];
  }
};

export const updateUserPermissions = async (uid, userRoles, userStores) => {
  try {
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/update_user_permissions`,
      //prettier-ignore
      { "admin_token": authToken, "uid": uid, "user_roles": userRoles, "user_stores": userStores }
    );
    console.log("resp from updateUserPermissions:", resp.data);
    return true;
  } catch (error) {
    console.log(error.message);
    console.log(error);
    return false;
  }
};

export const getIconForShortcutBtn = (
  iconName,
  iconColor = "rgb(230, 230, 230)"
) => {
  if (iconName === "coffee-outline") {
    return <LocalCafeOutlinedIcon style={{ color: iconColor }} />;
  }
  if (iconName === "tea-outline") {
    return <EmojiFoodBeverageOutlinedIcon style={{ color: iconColor }} />;
  }
  if (iconName === "glass-cocktail") {
    return <LocalBarOutlinedIcon style={{ color: iconColor }} />;
  }
  if (iconName === "food-croissant") {
    return <BakeryDiningOutlinedIcon style={{ color: iconColor }} />;
  }
  if (iconName === "cupcake") {
    return <CakeOutlinedIcon style={{ color: iconColor }} />;
  }
  if (iconName === "food-takeout-box-outline") {
    return <TakeoutDiningOutlinedIcon style={{ color: iconColor }} />;
  }
};

export const addLocation = async (formValue, enqueueSnackbar) => {
  try {
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/add_location`,
      //prettier-ignore
      { "admin_token": authToken, 
      "location_name": formValue?.locationName, 
      "location_id": formValue?.locationId,
      "location_types": formValue?.locationTypes,
      "location_pin": formValue?.locationPin,
      "latitude": formValue?.latitude,
      "longitude": formValue?.longitude
    }
    );
    console.log("resp from addLocation:", resp.data);
    enqueueSnackbar(
      `Successfully added ${formValue?.locationName} as a location`,
      {
        variant: "success",
      }
    );
    return true;
  } catch (error) {
    console.log(error.message);
    console.log(error?.response);
    // enqueueSnackbar(
    //   error?.response?.message ||
    //     `Something went wrong when trying to add ${formValue?.locationName} as a location`,
    //   {
    //     variant: "error",
    //   }
    // );
    if (error?.response?.status === 409) {
      enqueueSnackbar(
        ` ${formValue?.locationName} Already exists as a location`,
        {
          variant: "error",
        }
      );
    } else {
      enqueueSnackbar(
        `Something went wrong when trying to add ${formValue?.locationName} as a location`,
        {
          variant: "error",
        }
      );
    }

    return false;
  }
};

export const calculateTimestamps = (timePeriod) => {
  if (timePeriod?.toLowerCase() === "today") {
    let d = new Date();
    d.setHours(0, 0, 0, 0);
    let startOfDay = d.getTime();
    let endDate = d.getTime() + 86400000;

    console.log("startOfDay", startOfDay);
    console.log("endDate", endDate);
    return {
      startTimestamp: startOfDay,
      endTimestamp: endDate,
    };
  } else if (timePeriod?.toLowerCase() === "yesterday") {
    let d = new Date();
    d.setHours(0, 0, 0, 0);
    let startOfDay = d.getTime();
    let startOfYesterday = d.getTime() - 86400000;

    return { startTimestamp: startOfYesterday, endTimestamp: startOfDay };
  } else if (timePeriod?.toLowerCase() === "last week") {
    let endOfWeek = getPreviousMonday();
    let startOfWeek = endOfWeek - 604800000;

    return { startTimestamp: startOfWeek, endTimestamp: endOfWeek };
  } else if (timePeriod?.toLowerCase() === "this month") {
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 2, 0);
    console.log("firstDay:", firstDay);
    console.log("lastDay:", lastDay);

    return {
      startTimestamp: firstDay.getTime(),
      endTimestamp: lastDay.getTime(),
    };
  } else if (timePeriod?.toLowerCase() === "last month") {
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    console.log("last month firstDay:", firstDay);
    console.log("last month lastDay:", lastDay);

    return {
      startTimestamp: firstDay.getTime(),
      endTimestamp: lastDay.getTime(),
    };
  } else {
    return { startTimestamp: null, endTimestamp: null };
  }
};

export const getRegisteredDevices = async (enqueueSnackbar) => {
  try {
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", authToken);
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/get_registered_devices`,
      //prettier-ignore
      { "admin_token": authToken, }
    );
    console.log("resp from getRegisteredDevices:", resp.data);
    return resp.data;
  } catch (error) {
    console.log(error.message);
    console.log(error);
    enqueueSnackbar(
      `Something went wrong when trying to fetch registered devices`,
      {
        variant: "error",
      }
    );
    return null;
  }
};

export const getUnegisteredDevices = async (enqueueSnackbar) => {
  try {
    const authToken = window.localStorage.getItem("auth_token");
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/get_unregistered_devices`,
      //prettier-ignore
      { "admin_token": authToken, }
    );
    console.log("resp from getUnegisteredDevices:", resp.data);
    return resp.data;
  } catch (error) {
    console.log(error.message);
    console.log(error);
    enqueueSnackbar(
      `Something went wrong when trying to fetch unregistered devices`,
      {
        variant: "error",
      }
    );
    return null;
  }
};

export const unregisterDevice = async (deviceToUnregister, enqueueSnackbar) => {
  try {
    const authToken = window.localStorage.getItem("auth_token");
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/unregister_device`,
      //prettier-ignore
      { admin_token: authToken, location_id: deviceToUnregister?.location_id, device_id: deviceToUnregister?.device_id }
    );
    console.log("resp from unregisterDevice:", resp.data);
    enqueueSnackbar(`Succesfully unregistered device`, {
      variant: "success",
    });
    return true;
  } catch (error) {
    console.log(error);
    enqueueSnackbar(`Something went wrong when trying to unregister device`, {
      variant: "error",
    });

    return false;
  }
};

export const resendPasswordResetEmail = async (email, enqueueSnackbar) => {
  try {
    const authToken = window.localStorage.getItem("auth_token");
    const resp = await axios.post(
      `${REACT_APP_API_ADDRESS}/resend_password_reset_email`,
      //prettier-ignore
      { admin_token: authToken, email: email }
    );
    console.log("resp from resendPasswordResetEmail:", resp.data);
    enqueueSnackbar(`Succesfully resent invite`, {
      variant: "success",
    });
  } catch (error) {
    console.log(error);
    enqueueSnackbar(`Something went wrong when trying to resend invite`, {
      variant: "error",
    });
  }
};
